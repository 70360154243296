require('./libs');

require('trumbowyg/dist/trumbowyg');
require('trumbowyg/dist/langs/es');

import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

// Sortable

let listSortableDiv = document.getElementById('list-sortable');
if (listSortableDiv) {
    let listSortable = Sortable.create(listSortableDiv, {
        group: 'shared',
        animation: 150,
        dataIdAttr: 'data-id',
        //handle: '.handle',
        store: {
            set: function () {
                $.ajax({
                    method: listSortableDiv.dataset['method'],
                    url: listSortableDiv.dataset['url'],
                    headers: window.ajax_default_headers,
                    data: {
                        data: listSortable.toArray()
                    },
                    dataType: 'json',
                    success: function (data, textStatus, jqXHR) {
                        console.log(data, textStatus);
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.warn(textStatus, errorThrown);
                    },
                    complete: function (jqXHR, textStatus) {
                        console.log(textStatus);
                    }
                });
            }
        }
    });
}

// Trumbowyg

$.trumbowyg.svgPath = '/images/vendor/trumbowyg/icons.svg';

$('.trumbowyg').trumbowyg({
    lang: 'es',
    btns: [
        ['viewHTML'],
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['link'],
        //['insertImage'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen']
    ]
});
